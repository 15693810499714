<template>
    <div class="d-flex align-items-center">
      <template v-if="opened">
        <div class="mobile-holder position-absolute bg-white transform-none border-radius-3 z-index-200">
            <Item v-for="item in items" v-bind="item" v-bind:key="item.id"/>
        </div>
        <i class="notranslate material-icons cursor-pointer hover-opacity-6 navigation-color ml-10 ml-40-sm" v-on:click="toggle()">close</i>
      </template>
      <i v-else class="notranslate material-icons cursor-pointer hover-opacity-6 navigation-color ml-10 ml-40-sm" v-on:click="toggle()">menu</i>
    </div>
</template>

<script>
	import Item from './MobileItem.vue'

	export default {
		components: {
			Item,
        },
    	props: {
    	    items: Array
        },
        data: function() {
			return {
				opened: false
			}
		},
        methods: {
        	toggle: function() {
        		this.opened = !this.opened;
            }
        },
		watch: {
			opened() {
				if(!this.opened) return;
                let openEvent = document.createEvent('Event');
                openEvent.initEvent('navigationDropDownOpen', true, true);
                document.dispatchEvent(openEvent);
			}
		}
    }
</script>

<style scoped lang="scss">
    .mobile-holder {
      right: 0;
      top: 68px;
      width: 100%;
      height: 91vh;
      overflow-y: auto;

      .mobile-item:last-of-type {
        border-bottom: 1px solid #dee2e6;
      }
    }
</style>