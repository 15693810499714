<template>
    <div class="footer-new-holder-right-holder__socials d-flex justify-content-xl-end">
        <a v-for="profile in data" :key="profile.id" :id="gaTrackingFooterLinkId + profile.id" :href="profile.url" rel="noopener noreferrer nofollow" target="_blank">
          <img :src="profile.img" :alt="profile.alt">
        </a>
    </div>
</template>

<script>
export default {
    props: {
        data: Array
    },
    data() {
        return {
            gaTrackingFooterLinkId: 'hgr-tutorials-footer-link-'
        }
    }
}
</script>