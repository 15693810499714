<template>
    <div class="footer-new-holder-right-holder__brand">
        <svg :class="bigger ? 'd-block' : 'd-none d-sm-block'" width="150" height="30" viewBox="0 0 150 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M45.1114 8.89822H47.9253V21.3612H45.1114V16.0739H40.3857V21.3612H37.5718V8.89822H40.3857V13.6637H45.1114V8.89822Z"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M54.4949 15.1209C54.4949 15.732 54.5698 16.2835 54.7201 16.7752C54.8704 17.267 55.0871 17.6895 55.3698 18.0431C55.6518 18.3972 55.9978 18.6695 56.4069 18.8612C56.8155 19.0535 57.2843 19.1496 57.8137 19.1496C58.3305 19.1496 58.7966 19.0535 59.2117 18.8612C59.6261 18.6695 59.9752 18.3972 60.2574 18.0431C60.5399 17.6895 60.7568 17.267 60.9071 16.7752C61.0574 16.2835 61.1326 15.732 61.1326 15.1209C61.1326 14.5091 61.0574 13.9546 60.9071 13.4569C60.7568 12.9595 60.5399 12.5342 60.2574 12.1802C59.9752 11.8266 59.6261 11.5535 59.2117 11.3621C58.7966 11.1702 58.3305 11.0744 57.8137 11.0744C57.2843 11.0744 56.8155 11.1732 56.4069 11.3709C55.9978 11.5688 55.6518 11.8447 55.3698 12.1985C55.0871 12.5521 54.8704 12.9776 54.7201 13.475C54.5698 13.9729 54.4949 14.5214 54.4949 15.1209ZM64.0369 15.1209C64.0369 16.1877 63.8773 17.1262 63.5593 17.935C63.2402 18.7445 62.8041 19.4219 62.2513 19.9672C61.6982 20.5131 61.0397 20.9235 60.2762 21.1991C59.5128 21.4753 58.6918 21.6131 57.8144 21.6131C56.9604 21.6131 56.1551 21.4753 55.3974 21.1991C54.6398 20.9235 53.9782 20.5131 53.4133 19.9672C52.8478 19.4219 52.4034 18.7445 52.0786 17.935C51.754 17.1262 51.5913 16.1877 51.5913 15.1209C51.5913 14.0537 51.7598 13.1154 52.0965 12.3064C52.4329 11.4969 52.8872 10.8164 53.4584 10.2649C54.0292 9.71341 54.6907 9.29998 55.4426 9.02411C56.1937 8.74799 56.9846 8.60993 57.8144 8.60993C58.6679 8.60993 59.4734 8.74799 60.2313 9.02411C60.9887 9.29998 61.65 9.71341 62.2152 10.2649C62.7802 10.8164 63.2253 11.4969 63.5499 12.3064C63.8748 13.1154 64.0369 14.0537 64.0369 15.1209Z"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M71.1123 19.2212C71.5091 19.2212 71.8367 19.1885 72.0952 19.1221C72.3537 19.0565 72.5613 18.9667 72.7174 18.852C72.8735 18.7386 72.982 18.6038 73.0423 18.4479C73.1025 18.2922 73.1326 18.1182 73.1326 17.9263C73.1326 17.5189 72.9399 17.1797 72.5552 16.9104C72.1704 16.6403 71.5091 16.3498 70.5713 16.0375C70.1623 15.8942 69.7534 15.7289 69.3446 15.5433C68.9358 15.3578 68.569 15.1239 68.2444 14.842C67.9201 14.5603 67.6553 14.2186 67.451 13.8164C67.2464 13.4151 67.1443 12.9267 67.1443 12.3511C67.1443 11.7755 67.2525 11.2569 67.4689 10.7954C67.6855 10.3337 67.992 9.94143 68.389 9.61728C68.7857 9.29338 69.2664 9.04517 69.8316 8.87089C70.3968 8.69737 71.0339 8.60986 71.7436 8.60986C72.5853 8.60986 73.3129 8.70039 73.9263 8.87995C74.5391 9.05975 75.0443 9.25792 75.441 9.47368L74.6297 11.6857C74.2806 11.5059 73.8927 11.3469 73.4662 11.2089C73.0392 11.0713 72.5252 11.0019 71.9242 11.0019C71.2506 11.0019 70.7666 11.0955 70.472 11.2811C70.1774 11.4669 70.0298 11.7518 70.0298 12.1351C70.0298 12.3632 70.0843 12.5553 70.1925 12.7107C70.3005 12.8666 70.4541 13.0074 70.6523 13.1334C70.8508 13.2592 71.0793 13.3733 71.3381 13.4749C71.5961 13.577 71.8818 13.6817 72.1948 13.7895C72.8438 14.0297 73.409 14.2663 73.8902 14.5002C74.3709 14.7341 74.7709 15.0069 75.0897 15.3185C75.408 15.6301 75.6456 15.996 75.8022 16.4157C75.9581 16.8357 76.0365 17.3449 76.0365 17.9439C76.0365 19.107 75.6274 20.0093 74.8098 20.6506C73.9921 21.2924 72.7595 21.6133 71.1123 21.6133C70.5592 21.6133 70.0601 21.5801 69.6152 21.5142C69.1703 21.4478 68.7766 21.3671 68.4339 21.2712C68.0913 21.1754 67.7965 21.0736 67.5498 20.9657C67.3034 20.8576 67.0961 20.756 66.9276 20.6597L67.7216 18.4298C68.0939 18.634 68.5539 18.8166 69.1015 18.978C69.6483 19.1402 70.3189 19.2212 71.1123 19.2212Z"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M88.4327 8.89829V11.2903H84.6629V21.3613H81.8492V11.2903H78.0792V8.89829H88.4327Z"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M91.5579 21.3616H94.3718V8.89834H91.5579V21.3616Z"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M106.967 21.3613C106.162 19.9347 105.29 18.5261 104.352 17.135C103.414 15.744 102.416 14.4313 101.358 13.1963V21.3613H98.58V8.89832H100.871C101.267 9.29364 101.706 9.77925 102.187 10.3549C102.668 10.9305 103.158 11.5451 103.657 12.1985C104.156 12.8518 104.652 13.5293 105.145 14.2304C105.638 14.9318 106.101 15.606 106.534 16.2535V8.89832H109.33V21.3613H106.967Z"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M119.634 11.0564C118.324 11.0564 117.376 11.419 116.793 12.1443C116.21 12.8698 115.919 13.8616 115.919 15.121C115.919 15.7319 115.99 16.2869 116.135 16.7843C116.279 17.2815 116.496 17.71 116.784 18.0699C117.073 18.4297 117.433 18.7086 117.866 18.9065C118.299 19.1042 118.804 19.203 119.381 19.203C119.694 19.203 119.962 19.197 120.184 19.1852C120.406 19.1733 120.602 19.1495 120.77 19.1132V14.7793H123.584V20.9478C123.247 21.0798 122.706 21.2204 121.961 21.37C121.215 21.5196 120.295 21.5951 119.201 21.5951C118.263 21.5951 117.412 21.451 116.649 21.1635C115.885 20.8753 115.233 20.4561 114.692 19.9044C114.151 19.3531 113.733 18.6754 113.438 17.8722C113.143 17.0695 112.996 16.1521 112.996 15.121C112.996 14.0777 113.159 13.1545 113.484 12.351C113.808 11.548 114.252 10.867 114.818 10.3098C115.383 9.75224 116.047 9.32976 116.811 9.04207C117.575 8.75413 118.389 8.60978 119.255 8.60978C119.844 8.60978 120.376 8.64926 120.851 8.72697C121.326 8.80518 121.735 8.89496 122.078 8.99706C122.421 9.09865 122.703 9.20352 122.926 9.31165C123.148 9.41954 123.307 9.50378 123.404 9.56364L122.592 11.8113C122.207 11.6076 121.765 11.4311 121.266 11.281C120.767 11.1311 120.223 11.0564 119.634 11.0564Z"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M127.521 21.3613V8.89832H135.963V11.2542H130.335V13.6998H135.331V16.0021H130.335V19.0055H136.378V21.3613H127.521Z"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M143.596 11.1462C143.392 11.1462 143.209 11.1522 143.046 11.164C142.884 11.1764 142.731 11.1884 142.586 11.2V14.5809H143.38C144.438 14.5809 145.195 14.4496 145.652 14.1855C146.109 13.9223 146.337 13.4724 146.337 12.8366C146.337 12.225 146.106 11.791 145.643 11.5325C145.18 11.2752 144.498 11.1462 143.596 11.1462ZM143.435 8.75414C145.311 8.75414 146.748 9.08659 147.746 9.75225C148.744 10.4177 149.243 11.4517 149.243 12.8547C149.243 13.7296 149.042 14.4405 148.639 14.986C148.236 15.5312 147.656 15.9602 146.898 16.2716C147.151 16.5836 147.415 16.9402 147.692 17.3418C147.968 17.7437 148.242 18.1634 148.513 18.601C148.783 19.0386 149.045 19.494 149.297 19.9673C149.549 20.4413 149.784 20.9058 150 21.3612H146.855C146.625 20.9536 146.393 20.5401 146.158 20.1204C145.922 19.701 145.681 19.2931 145.434 18.8972C145.187 18.5014 144.941 18.1272 144.701 17.7736C144.459 17.4195 144.217 17.0989 143.976 16.8107H142.587V21.3612H139.774V9.07754C140.387 8.95834 141.021 8.87384 141.676 8.82631C142.332 8.77803 142.918 8.75414 143.435 8.75414Z"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M0.000249566 14.046V0.000497794L7.08916 3.78046V10.1086L16.4735 10.1132L23.6774 14.046H0.000249566ZM18.3925 8.95058V0L25.6725 3.6859V13.1797L18.3925 8.95058ZM18.3924 26.1177V19.8441L8.93577 19.8375C8.9446 19.8793 1.6123 15.8418 1.6123 15.8418L25.6725 15.9547V30L18.3924 26.1177ZM0 26.1177L0.000252212 16.9393L7.08916 21.0683V29.8033L0 26.1177Z"/>
        </svg>
        <svg :class="bigger ? 'd-none' : 'd-block d-sm-none'" width="100" height="20" viewBox="0 0 100 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M30.0743 5.93215H31.9502V14.2408H30.0743V10.7159H26.9238V14.2408H25.0479V5.93215H26.9238V9.10915H30.0743V5.93215Z"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M36.3299 10.0806C36.3299 10.488 36.3799 10.8557 36.4801 11.1834C36.5803 11.5114 36.7247 11.793 36.9132 12.0287C37.1012 12.2648 37.3319 12.4464 37.6046 12.5741C37.877 12.7024 38.1896 12.7664 38.5425 12.7664C38.887 12.7664 39.1977 12.7024 39.4745 12.5741C39.7508 12.4464 39.9835 12.2648 40.1716 12.0287C40.3599 11.793 40.5045 11.5114 40.6047 11.1834C40.705 10.8557 40.7551 10.488 40.7551 10.0806C40.7551 9.67273 40.705 9.30306 40.6047 8.97127C40.5045 8.63966 40.3599 8.35616 40.1716 8.12011C39.9835 7.88439 39.7508 7.70232 39.4745 7.57474C39.1977 7.44682 38.887 7.38294 38.5425 7.38294C38.1896 7.38294 37.877 7.44883 37.6046 7.5806C37.3319 7.71255 37.1012 7.89646 36.9132 8.13234C36.7247 8.36806 36.5803 8.65173 36.4801 8.98334C36.3799 9.31529 36.3299 9.68094 36.3299 10.0806ZM42.6913 10.0807C42.6913 10.7918 42.5848 11.4175 42.3728 11.9567C42.1601 12.4964 41.8694 12.948 41.5008 13.3115C41.1321 13.6755 40.6931 13.9491 40.1841 14.1328C39.6751 14.3169 39.1278 14.4088 38.5429 14.4088C37.9736 14.4088 37.4367 14.3169 36.9316 14.1328C36.4265 13.9491 35.9855 13.6755 35.6088 13.3115C35.2318 12.948 34.9356 12.4964 34.719 11.9567C34.5026 11.4175 34.3942 10.7918 34.3942 10.0807C34.3942 9.36916 34.5065 8.74365 34.731 8.20431C34.9553 7.66464 35.2581 7.21098 35.6389 6.84332C36.0194 6.47566 36.4605 6.20004 36.9617 6.01612C37.4624 5.83204 37.9897 5.74 38.5429 5.74C39.1119 5.74 39.6489 5.83204 40.1542 6.01612C40.6591 6.20004 41.1 6.47566 41.4768 6.84332C41.8534 7.21098 42.1502 7.66464 42.3666 8.20431C42.5831 8.74365 42.6913 9.36916 42.6913 10.0807Z"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M47.4082 12.8141C47.6727 12.8141 47.8911 12.7923 48.0635 12.7481C48.2358 12.7043 48.3742 12.6445 48.4783 12.568C48.5823 12.4924 48.6546 12.4025 48.6948 12.2986C48.735 12.1948 48.755 12.0788 48.755 11.9509C48.755 11.6793 48.6266 11.4531 48.3701 11.2736C48.1136 11.0935 47.6727 10.8999 47.0475 10.6917C46.7748 10.5961 46.5023 10.486 46.2297 10.3622C45.9571 10.2385 45.7127 10.0826 45.4963 9.89465C45.28 9.70688 45.1035 9.47904 44.9673 9.21097C44.8309 8.94339 44.7628 8.61781 44.7628 8.23406C44.7628 7.8503 44.835 7.50461 44.9792 7.19697C45.1237 6.88916 45.328 6.62762 45.5926 6.41152C45.8571 6.19558 46.1776 6.03011 46.5544 5.91393C46.9312 5.79825 47.3559 5.73991 47.8291 5.73991C48.3902 5.73991 48.8752 5.80026 49.2842 5.91996C49.6927 6.03984 50.0295 6.17194 50.294 6.31579L49.7531 7.79045C49.5204 7.67058 49.2618 7.56463 48.9775 7.47259C48.6928 7.38088 48.3501 7.33461 47.9495 7.33461C47.5003 7.33461 47.1777 7.39697 46.9813 7.5207C46.7849 7.6446 46.6865 7.83455 46.6865 8.09005C46.6865 8.24211 46.7229 8.37019 46.795 8.4738C46.867 8.57774 46.9694 8.67163 47.1015 8.75562C47.2338 8.83945 47.3862 8.91556 47.5587 8.98329C47.7307 9.05136 47.9212 9.1211 48.1299 9.19303C48.5625 9.35313 48.9393 9.51089 49.2601 9.66681C49.5806 9.82273 49.8473 10.0046 50.0598 10.2123C50.272 10.4201 50.4304 10.664 50.5348 10.9438C50.6387 11.2238 50.691 11.5633 50.691 11.9626C50.691 12.738 50.4183 13.3396 49.8732 13.7671C49.328 14.1949 48.5063 14.4088 47.4082 14.4088C47.0395 14.4088 46.7067 14.3867 46.4101 14.3428C46.1135 14.2985 45.8511 14.2447 45.6225 14.1808C45.3942 14.117 45.1977 14.0491 45.0332 13.9771C44.8689 13.905 44.7307 13.8373 44.6184 13.7731L45.1477 12.2865C45.3959 12.4227 45.7026 12.5444 46.0676 12.652C46.4321 12.7602 46.8792 12.8141 47.4082 12.8141Z"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M58.9551 5.93219V7.52689H56.4419V14.2408H54.5661V7.52689H52.0527V5.93219H58.9551Z"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M61.0386 14.241H62.9145V5.93223H61.0386V14.241Z"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M71.3115 14.2409C70.7745 13.2898 70.1934 12.3508 69.5679 11.4233C68.9424 10.496 68.2771 9.62088 67.5719 8.79755V14.2409H65.72V5.93221H67.247C67.5113 6.19576 67.8041 6.51949 68.1249 6.90325C68.4455 7.287 68.7719 7.69674 69.1048 8.1323C69.4374 8.56786 69.7681 9.01952 70.0969 9.48693C70.4254 9.95451 70.7341 10.404 71.0228 10.8357V5.93221H72.8867V14.2409H71.3115Z"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M79.7557 7.37094C78.8824 7.37094 78.2509 7.61269 77.862 8.0962C77.4732 8.57987 77.279 9.24109 77.279 10.0807C77.279 10.4879 77.3269 10.8579 77.4231 11.1895C77.5195 11.521 77.6637 11.8067 77.8559 12.0466C78.0486 12.2865 78.2889 12.4724 78.5776 12.6043C78.8661 12.7361 79.2026 12.802 79.5874 12.802C79.7958 12.802 79.9743 12.798 80.1228 12.7901C80.2709 12.7822 80.4012 12.7663 80.5134 12.7422V9.85285H82.3893V13.9652C82.1647 14.0532 81.8042 14.1469 81.307 14.2467C80.8101 14.3464 80.1968 14.3967 79.4672 14.3967C78.8421 14.3967 78.2747 14.3006 77.766 14.109C77.2567 13.9169 76.8218 13.6374 76.461 13.2696C76.1007 12.9021 75.8219 12.4503 75.6255 11.9148C75.4288 11.3797 75.3309 10.7681 75.3309 10.0807C75.3309 9.3851 75.4392 8.76965 75.6558 8.23401C75.872 7.69869 76.1683 7.24469 76.5454 6.87318C76.9222 6.50149 77.3649 6.21984 77.8741 6.02805C78.383 5.83608 78.9261 5.73985 79.5034 5.73985C79.8961 5.73985 80.2507 5.76617 80.5675 5.81798C80.8841 5.87012 81.1565 5.92997 81.385 5.99804C81.6137 6.06577 81.8022 6.13568 81.9505 6.20777C82.099 6.27969 82.2047 6.33585 82.2691 6.37576L81.728 7.87423C81.4713 7.73843 81.1767 7.62074 80.8443 7.52065C80.5114 7.42073 80.1488 7.37094 79.7557 7.37094Z"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M85.0142 14.2409V5.93221H90.6417V7.50277H86.8899V9.13318H90.2208V10.668H86.8899V12.6703H90.9184V14.2409H85.0142Z"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M95.7309 7.43079C95.5946 7.43079 95.4727 7.43482 95.3643 7.4427C95.2563 7.45091 95.154 7.45896 95.0577 7.46667V9.72058H95.5868C96.2919 9.72058 96.797 9.63307 97.1014 9.45703C97.4062 9.2815 97.5583 8.98157 97.5583 8.55775C97.5583 8.15002 97.404 7.86065 97.0954 7.68831C96.7868 7.5168 96.332 7.43079 95.7309 7.43079ZM95.6233 5.83609C96.8739 5.83609 97.8316 6.05773 98.497 6.5015C99.1623 6.94511 99.4952 7.63449 99.4952 8.56982C99.4952 9.15308 99.3611 9.62703 99.0924 9.99067C98.824 10.3541 98.4371 10.6401 97.932 10.8477C98.1005 11.0558 98.2767 11.2935 98.461 11.5612C98.6453 11.8291 98.8279 12.1089 99.0083 12.4007C99.1885 12.6924 99.3631 12.996 99.5314 13.3115C99.6995 13.6275 99.8559 13.9372 100 14.2408H97.9031C97.7501 13.969 97.5951 13.6934 97.4385 13.4136C97.2815 13.134 97.1209 12.862 96.9556 12.5982C96.7912 12.3343 96.6276 12.0848 96.467 11.8491C96.3058 11.613 96.1447 11.3993 95.9841 11.2071H95.058V14.2408H93.1824V6.05169C93.5911 5.97223 94.014 5.91589 94.4508 5.88421C94.8877 5.85202 95.2784 5.83609 95.6233 5.83609Z"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M0.000249566 9.36418V0.000497794L4.72619 2.52047V6.73926L10.9824 6.74227L15.785 9.36418H0.000249566ZM12.2616 5.96706V0L17.115 2.45727V8.78646L12.2616 5.96706ZM12.2616 17.4118V13.2294L5.9572 13.225C5.96308 13.2529 1.07489 10.5612 1.07489 10.5612L17.115 10.6365V20L12.2616 17.4118ZM0 17.4118L0.000168141 11.2928L4.72611 14.0455V19.8689L0 17.4118Z"/>
        </svg>
    </div>
</template>
<script>
    export default {
    	props: {
            bigger: {
                type: Boolean,
                default: false
            }
        }
    }
</script>

<style lang="scss" scoped>
    path {
        fill: #2F1C6A;
        transition: all 0.3s ease-in-out;
    }
</style>