<template>
  <div v-if="show" class="gdpr-popup-wrapper">
    <div class="gdpr-popup gdpr-popup--new">
      <i id="hgr-cookie_consent-settings-close_btn" class="popup-close-btn notranslate material-icons fs-18 position-absolute pointer" aria-hidden="true" @click="closePopup"> close </i>
      <h5>{{ strings.header.title }}</h5>
      <p class="f-body text-h-gray mt-5 mb-20 mb-25-sm" v-html="strings.header.body"></p>
      <div class="gdpr-bottom-banner__button d-flex flex-column align-items-center d-md-block">
        <div id="hgr-cookie_consent-settings-confirm_btn"
           class="text-h-meteorite-dark link hover-text-primary cursor-pointer mr-20 mt-20 mt-30-sm d-inline-block" @click="setPopupCookies">{{ strings.buttonTxt }}</div>
        <button id="hgr-cookie_consent-settings-confirm_all_btn"
              class="hb hb--primary hb--uplift-radius hb--h50 hb--255 hb--h50-sm hb--160-sm mb-20 mb-30-sm mx-auto ml-0-sm mr-0-sm d-inline-block" @click="setAllCookies">{{ strings.header.btn }}</button>
      </div>
      <div v-for="(cookie, index) in strings.cookies" :key="cookie.title" :class="{'mb-20': index !== strings.cookies.length - 1 }" class="gdpr-popup__cookie">
        <div class="w-100 d-flex justify-content-between">
          <p class="pt-title">{{ cookie.title }}</p>
          <p v-if="cookie.btn" class="f-body text-h-gray text-right text-nowrap">{{ cookie.btn }}</p>
          <label v-else class="gdpr-popup__switch">
            <input :id="`hgr-cookie_consent-settings-${cookie.cookieName}_switch`"
                   @change="setCookieSet(cookie.cookieName)" v-model="checked[cookie.cookieName]" class="gdpr-popup-switch__checkbox" type="checkbox">
            <span class="gdpr-popup-switch__slider"></span>
          </label>
        </div>
        <p class="f-body text-h-gray mt-5">{{ cookie.body }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    show: Boolean,
    strings: Object,
    setCookies: Function,
    setAllCookies: Function,
    closePopup: Function
  },
  data() {
    return {
      cookieSet: [],
      checked: {
        statistics: false,
        advertising: false
      },
      vh: window.innerHeight * 0.01
    }
  },
  created() {
    this.setMobileHeights();
    window.addEventListener('resize', this.debounce(this.setMobileHeights, 200, false), false);
  },
  methods: {
    debounce(func, wait, immediate) {
      let timeout;
      return () => {
        const context = this,
            args = arguments;
        const later = function () {
          timeout = null;
          if (!immediate) func.apply(context, args);
        };
        const callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
      };
    },
    setMobileHeights() {
      setTimeout(() => {
        this.vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${this.vh}px`);
      }, 100);
    },
    setCookieSet(cookie) {
      if (!this.checked[cookie] && this.cookieSet.includes(cookie)) {
        const index = this.cookieSet.indexOf(cookie);
        if(index !== -1){
          this.cookieSet.splice(index, 1);
        }
        return;
      }
      this.cookieSet.push(cookie);
    },
    setPopupCookies() {
      this.setCookies(this.cookieSet);
      document.querySelectorAll('html')[0].classList.remove('overflow-hidden');
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.debounce(this.setMobileHeights, 200, false), false);
  }
}
</script>
