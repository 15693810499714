<template>
  <div class="container">
    <div class="footer-new__holder position-relative">
      <div class="footer-new-holder__left">
        <Logo bigger class="mb-15"/>
        <p class="footer-new-holder-right-holder__text pt-15-lg mb-40-md mb-20">{{ formattedAbout }}</p>
        <PaymentMethods v-bind="payments"/>
      </div>
      <MenuItems :menu-items="menuItems"/>
      <div class="d-none d-xl-block position-absolute right-0 bottom-0">
        <Socials :data="socialLinks"/>
      </div>
    </div>
    <div class="d-xl-none">
      <Socials :data="socialLinks"/>
    </div>
    <FooterBottom :copyright="copyright" :vat="noVatTxt"/>
  </div>
</template>F

<script>
import Logo from './partial/Logo'
import Socials from './partial/Socials'
import PaymentMethods from './partial/PaymentMethods'
import MenuItems from './partial/MenuItems'
import FooterBottom from './partial/FooterBottom'

export default {
  name: 'FooterNew',
  components: {
    Logo,
    Socials,
    PaymentMethods,
    MenuItems,
    FooterBottom
  },
  props: {
    menuItems: Object,
    paymentMethods: [Object, Array],
    paymentUrl: String,
    paymentPath: String,
    paymentBtn: String,
    about: String,
    socialLinks: [Object, Array],
    copyright: String,
    noVatTxt: String
  },
  data() {
    return {
      payments: {
        paymentMethods: this.paymentMethods,
        paymentPath: this.paymentPath,
        paymentBtn: this.paymentBtn,
        paymentUrl: this.paymentUrl
      }
    }
  },
  computed: {
    formattedAbout() {
      return this.about.replace("<br>", "")
    }
  }
}
</script>
