<template>
  <div ref="holder">
    <div v-if="!close" class="gdpr-bottom-banner position-fixed bottom-0 z-index-1001 w-100 gdpr-bottom-banner--new">
      <div class="d-flex justify-content-between align-items-center flex-column">
        <div class="container w-100">
          <div class="gdpr-bottom-banner__text-block d-flex flex-wrap mb-15 mb-25-sm">
            <div class="col-12 p-0 pr-15-md mb-15 mb-0-md">
              <h4 class="mt-0 mb-10 mb-20-md">{{ body.firstHeading }}</h4>
              <p class="paragraph" v-html="body.firstBody"></p>
            </div>
          </div>
          <div class="gdpr-bottom-banner__button d-flex flex-column align-items-center d-md-block">
            <button id="hgr-cookie_consent-decline_btn" class="hb hb--h50 hb--255 hb--outline hb--uplift-radius mr-0 mr-20-md mr-20-lg d-inline-block" @click="declineCookies">{{ decline }}</button>
            <button id="hgr-cookie_consent-settings_btn" class="hb hb--h50 hb--255 hb--outline hb--uplift-radius mr-0 mr-20-md mr-20-lg d-inline-block" @click="showSettings">{{ settings }}</button>
            <button id="hgr-cookie_consent-accept_all_btn" class="hb hb--h50 hb--255 hb--primary hb--uplift-radius mr-0 mr-20-md mr-20-lg d-inline-block" @click="setAllCookies">{{ accept }}</button>
          </div>
        </div>
      </div>
      <GdprPopup :show="showPopup" :close-popup="closePopup" :strings="strings.popup" :set-cookies="setCookies" :set-all-cookies="setAllCookies"></GdprPopup>
    </div>
  </div>
</template>

<script>
import GdprPopup from './GdprPopup';
import { gtag, install } from 'ga-gtag';
export default {
  components: {
    GdprPopup
  },
  props: {
    strings: Object,
  },
  data() {
    return {
      body: this.strings.banner.body,
      accept: this.strings.banner.acceptBtn,
      settings: this.strings.banner.settingsBtn,
      decline: this.strings.banner.declineBtn,
      close: true,
      showPopup: false
    }
  },
  async created() {
    this.initiateGA();
    const cookie = this.$cookies.get('cookie_consent');
    if (cookie) return;

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'cookie_consent_seen'
    });
    this.close = false;
  },
  updated() {
    const cookieSettingsBtn = document.getElementById('hgr-cookie_consent-show_settings_btn');
    if (!cookieSettingsBtn) return;
    cookieSettingsBtn.addEventListener('click', this.showSettings);
  },
  methods: {
    closeBanner() {
      this.close = true;
      this.setCookies([]);
    },
    showSettings() {
      this.showPopup = true;
      this.$refs.holder.classList.remove('overflow-scroll');
      setTimeout(() => document.querySelectorAll('html')[0].classList.add('overflow-hidden'), 0);
    },
    closePopup() {
      this.showPopup = false;
      document.querySelectorAll('html')[0].classList.remove('overflow-hidden');
    },
    setAllCookies() {
      this.setCookies(["statistics", "advertising"]);
    },
    declineCookies() {
      this.setCookies([])
    },
    setCookies(cookieSet) {
      this.close = true;
      this.showPopup = false;
      this.$cookies.set('cookie_consent', JSON.stringify(cookieSet), '1y');

      const isAdvertisingGranted = cookieSet.includes('advertising');
      const consent = {
        ad_storage: isAdvertisingGranted ? 'granted' : 'denied',
        ad_user_data: isAdvertisingGranted ? 'granted' : 'denied',
        ad_personalization: isAdvertisingGranted ? 'granted' : 'denied',
        analytics_storage: cookieSet.includes('statistics') ? 'granted' : 'denied',
      };

      gtag('consent', 'default', consent);

      (window.dataLayer || []).push({
        event: 'consent_update',
        consent,
        _clear: true,
      });
      document.querySelectorAll('html')[0].classList.remove('overflow-hidden');
    },
    initiateGA () {
      (function (w, d, s, l, i) {
        w[l] = w[l] || [];
        w[l].push({
          'gtm.start': new Date().getTime(),
          event: 'gtm.js',
        });

        const query = new URLSearchParams(document.location.search);
        const analyticsParam = query.get('_ga');
        const gl = query.get('_gl');

        analyticsParam?.length < 1000 && globalThis.$cookies.set('_ga', String(analyticsParam));
        gl?.length < 1000 && globalThis.$cookies.set('_gl', String(gl));

        w[l].push({
          hostname: globalThis.location.hostname.replace('www.', ''),
        });  

        const f = d.getElementsByTagName(s)[0];
        const j = d.createElement(s);
        const dl = l != 'dataLayer' ? '&l=' + l : '';

        j.async = true;
        j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
        f.parentNode?.insertBefore(j, f);
      })(window, document, 'script', 'dataLayer', 'GTM-KL4FQVG');

      install('GTM-KL4FQVG');
    }
  }
}
</script>
