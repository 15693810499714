<template>
  <div class="mobile-item">
    <div class="border-top pl-15 pr-15 pr-40-sm pl-40-sm" :class="opened ? 'border-bottom' : ''">
      <template v-if="hasDropdown()">
        <div v-on:click="opened = !opened"
             class="pt-15 pb-15 d-flex justify-content-between align-items-center cursor-pointer h-menu-mobile-item-hover-change">
          <p class="fs-16 fw-800 text-h-dark-gray hover-text-primary transition-3">{{ title }}</p>
          <span class="material-icons hover-text-primary text-h-primary transition-3">{{ opened ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}</span>
        </div>
      </template>
      <a v-else class="pt-15 pb-15 d-block pt-title text-h-gray-dark hover-text-primary" :href="href">{{ title }}</a>
    </div>
    <div v-if="opened" class="ghost-white pl-15 pr-15 pr-40-sm pl-40-sm">
      <div v-for="(item, index) in items" :class="items.length - 1 === index ? '' : 'border-bottom'">
        <a :id="item.id" v-if="!item.dropDown" class="fs-16 fw-800 d-block text-h-dark-gray hover-text-primary transition-3 p-15" :href="item.href">{{ item.title }}</a>
        <template v-else>
          <div v-on:click="updateExpanded(item.title)" class="d-flex justify-content-between align-items-center cursor-pointer h-menu-mobile-item-hover-change">
            <p class="fs-16 fw-800 text-h-dark-gray hover-text-primary transition-3 p-15">{{ item.title }}</p>
            <span class="material-icons hover-text-primary text-h-primary transition-3">{{ expanded === item.title ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}</span>
          </div>
          <template v-if="expanded === item.title">
            <div v-for="(child, childIndex) in item.items" :key="child.title" >
              <a :id="item.id" class="fs-16 fw-800 d-block text-h-dark-gray hover-text-primary transition-3 p-15 pl-30" :href="child.href">{{ child.title }}</a>
            </div>
          </template>
        </template>
      </div>
    </div>
  </div>
</template>
<script>
	export default {
		props: {
			title: String,
			href: String,
			logo: String,
			material: String,
			items: {
				type: Array,
				default: function() {
					return []
				}
			}
		},
    data() {
      return {
        opened: false,
        expanded: ""
      };
    },
		methods: {
		 updateExpanded(title) {
		   if(this.expanded === title) {
		     this.expanded = "";
		     return;
       }
		   this.expanded = title;
     },
			hasDropdown: function() {
				return this.items.length > 0
			},
		},
    computed: {
		  isOpened() {
        var itemIndex = this.items.findIndex((obj => obj.title === title));
        return this.items[itemIndex].opened;
      }
    }
	}
</script>