<template>
  <div class="footer-new-holder__right">
    <div class="footer-new-holder-right__holder col-12 col-md-4 col-xl-3 pl-0">
      <FooterBlock :category="menuItems.hosting"></FooterBlock>
    </div>

    <div class="footer-new-holder-right__holder col-12 col-md-4 col-xl-3 pl-0">
      <FooterBlock :category="menuItems.domains"></FooterBlock>
      <div class="d-xl-none">
        <FooterBlock :category="menuItems.help"></FooterBlock>
      </div>
    </div>
    <div class="footer-new-holder-right__holder col-12 col-md-4 col-xl-3 pl-0">
      <FooterBlock :category="menuItems.information"></FooterBlock>
      <div class="d-xl-none">
        <FooterBlock :category="menuItems.company"></FooterBlock>
      </div>
      <FooterBlock :category="menuItems.legal"></FooterBlock>
    </div>
    <div class="footer-new-holder-right__holder d-none d-xl-block col-12 col-md-4 col-xl-3 pl-0">
      <div class="d-xl-block">
        <FooterBlock :category="menuItems.company"></FooterBlock>
      </div>
      <div>
        <FooterBlock :category="menuItems.help"></FooterBlock>
      </div>
    </div>
  </div>
</template>

<script>
import FooterBlock from "./FooterBlock";

export default {
  components: {FooterBlock},
  props: {
    menuItems: Object
  },
  data: () => ({
    isMobile: false,
    menuItems: {},
    menuCopy: {}
  }),
  beforeUnmount() {
    if (typeof window !== 'undefined') {
      window.removeEventListener('resize', this.onResize, {passive: true})
    }
  },
  mounted() {
    this.onResize()
    window.addEventListener('resize', this.onResize, {passive: true})
  },
  created() {
    this.menuCopy = Object.assign({}, this.menuItems);
  },
  methods: {
    onResize() {
      this.isMobile = window.innerWidth < 430;

      if (this.menuItems !== this.menuCopy && Object.keys(this.menuCopy) !== 0) {
        this.menuItems = this.menuCopy;
      }
    }
  },
  computed: {},
}
</script>
