<template>
    <div class="footer-new__bottom">
        <p class="footer-new-bottom__copyright footer-new-bottom__text pb-10 pb-0-md">
            {{ copyright }}
        </p>
        <p class="footer-new-bottom__no-vat footer-new-bottom__text">
            {{ vat }}
        </p>
    </div>
</template>

<script>
export default {
    props: {
        copyright: String,
        vat: String
    }
}
</script>