<template>
  <div class="pt-40 pb-20 pl-15 pr-15 h-100 d-flex justify-content-center flex-column">
    <p class="title fw-800 mx-auto">{{ title }}</p>
    <div class="d-flex align-items-center justify-content-center mt-30 discount-area">
      <s class="currency-selector-price-symbol text-content-grey mr-5 pt-discounted-price text-white">{{
          Intl.NumberFormat(lang, {
            style: 'currency',
            currency: currency,
            minimumFractionDigits: 0,
            maximumFractionDigits: 2
          }).format(oldPrice)
        }}</s>
      <div class="bg-accent discount text-center fw-800 fs-14 border-radius-round pr-15 pl-15">{{
          percentDiscount
        }}
      </div>
    </div>
    <div class="price-block mt-5">
      <div class="price-block-wrapper pricing-table__price">
        <div class="text-h-black text-center fs-46 fw-800 d-flex justify-content-center align-self-end mt-20">
          <span class="lh-1 text-white">{{
              (Intl.NumberFormat(lang, {
                style: 'currency',
                currency: currency,
                minimumFractionDigits: 0,
                maximumFractionDigits: 2
              }).format(price))
            }}</span>
          <span class="fs-16 pt-title align-self-end text-white">{{ monthly }}</span>
        </div>
      </div>
    </div>
    <a :href="'//' + link" target="_blank" :id="gaId">
      <button class="mx-auto hb hb--h50 w-100 mt-20">{{ btnText }}</button>
    </a>
        <div class="feature-area">
          <div class="feature" v-for="feature in features">
            <img class="icon" :src="getFeatureIcon(feature.limited)"/>
            <span class="feature-text">{{ feature.text}}</span>
          </div>
        </div>
      </div>
    </template>

    <script>
    export default {
      name: 'RelatedProducts',
      components: {},
      mixins: [],
      props: {
        title: String,
        lang: String,
        currency: String,
        percentDiscount: String,
        price: String,
        oldPrice: String,
        monthly: String,
        gaId: String,
        link: String,
        btnText: String,
        features: [Object, Array],
        iconLimited: String,
        iconUnlimited: String
      },
      methods: {
        getFeatureIcon(limited) {
          if(limited === undefined || limited === false) {
            return this.iconUnlimited;
          }
          return this.iconLimited;
        }
      }
    }
    </script>