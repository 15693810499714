<template>
    <ul class="footer-new-holder-right-holder__payments">
        <li v-for="payment in paymentMethods" :key="payment" class="footer-new-holder-right-holder-payments__payment">
            <img width="60" height="40" class="h-100" :src="getPaymentIcon(payment)" :alt="payment">
        </li>
        <li class="footer-new-holder-right-holder-payments__link">
            <a :href="paymentPath" id="hgr-tutorials-footer-link-and_more">{{ paymentBtn }}</a>
        </li>
    </ul>
</template>

<script>
export default {
    props: {
        paymentMethods: Array,
        paymentPath: String,
        paymentBtn: String,
        paymentUrl: String
    },
    methods: {
        getPaymentIcon(title) {
            return `${this.paymentUrl}${title}.png`
        }
    },
}
</script>