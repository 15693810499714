<template>
  <div class="footer-new__list">
    <div class="footer-new-list__title">{{ category.category }}</div>
    <ul class="footer-new-list__holder">
      <li v-for="route in category.items" class="footer-new-list-holder__item"><a :href="route.pattern" :id="'test' + route.pattern.trim('/').replace('-', '_') + 'test'">{{ route.title }}</a></li>
    </ul>
  </div>
</template>
<script>
export default {
  props: {
    category: Object
  }
}
</script>