<template>
    <a id="hgr-tutorials-h-menu-item-cart" class="hover-opacity-6 d-flex ml-20 align-items-center navigation-color" :href="path">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" :fill="sticky.colored || sticky['colored-transparent'] ? 'black' : 'white'" width="24px" height="24px"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M15.55 13c.75 0 1.41-.41 1.75-1.03l3.58-6.49c.37-.66-.11-1.48-.87-1.48H5.21l-.94-2H1v2h2l3.6 7.59-1.35 2.44C4.52 15.37 5.48 17 7 17h12v-2H7l1.1-2h7.45zM6.16 6h12.15l-2.76 5H8.53L6.16 6zM7 18c-1.1 0-1.99.9-1.99 2S5.9 22 7 22s2-.9 2-2-.9-2-2-2zm10 0c-1.1 0-1.99.9-1.99 2s.89 2 1.99 2 2-.9 2-2-.9-2-2-2z"/></svg>
        <span v-if="count" class="rounded-circle bg-red d-flex justify-content-center align-items-center max-w-20 mw-20 h-20 cart-transform fs-10 text-white wm-cart-circle">{{ count }}</span>
        <p class="fs-15 fs-17-xxl fw-600 ml-n2 d-none d-sm-block" v-bind:class="count ? '' : 'ml-10'">{{ cart }}</p>
    </a>
</template>

<script>
    export default {
		props: {
			cart: String,
			path: String,
			count: [Number, String],
            sticky: Object
		}
    }
</script>

<style lang="scss">
    .cart-transform {
        transform: translate(-60%, -50%);
    }
</style>