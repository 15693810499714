<template>
  <div class="w-100 d-flex align-items-center z-index-200 navigation-holder" :class="sticky">
    <div class="pl-15 pr-15 pr-40-sm pl-40-sm d-flex align-items-center justify-content-between w-100">
      <div class="d-flex align-items-center">
        <Logo :blog-path="blogPath" :tutorials-name="tutorialsName" :tld="themeTld"
              :color="sticky === 'colored' ? '#000000' : '#ffffff'"/>
      </div>
      <div class="align-items-center d-none d-xl-flex">
        <Item class="d-none d-xl-block" v-for="(item, index) in getMainMenuItems" v-bind="item" v-bind:key="item.id"
              :index="index" :dropdown="dropdowns[index]" :sticky="sticky" @toggle="toggleDropdowns"
              @close="closeDropdowns" :is-login="item.nameIsLogin"></Item>
      </div>
      <div class="d-flex d-xl-none">
        <Mobile v-if="isPageLoaded" v-bind="getMobile()"/>
      </div>
    </div>
  </div>
</template>
<script>
import Mixin from '../../mixins/bodyElementManipulation'
import Logo from './new/Logo.vue'
import Item from './new/Item.vue'
import Mobile from './new/Mobile.vue'
import Cart from './new/Cart'

export default {
  name: 'HmenuNew',
  components: {
    Logo,
    Item,
    Mobile,
    Cart
  },
  mixins: [Mixin],
  props: {
    menuItems: [Object, Array],
    cartPath: String,
    homePath: String,
    blogPath: String,
    tutorialsName: String,
    cart: String,
    themeTld: String,
    showPhoneNumber: Boolean,
    navigationTop: Boolean,
  },
  data() {
    return {
      isPageLoaded: false,
      dropdowns: {
        type: Array,
        default: function () {
          return Array(this.menuItems.length + 1).fill(false);
        }
      },
      scrollPosition: null,
      cartItemsCount: $cookies.get("cartItemsCount") > 0 ? $cookies.get("cartItemsCount") : 0,
    }
  },
  methods: {
    closeMobileMenu() {
      this.showMobileMenu = false;
    },
    openMobileMenu() {
      this.showMobileMenu = true;
    },
    toggleDropdowns: function (index, isOpened) {
      setTimeout(() => {
        if (isOpened) {
        } else if (this.dropdowns[index]) {
          this.dropdowns[index] = false;
        } else {
          this.dropdowns = Array(this.getMainMenuItems.length + 1).fill(false);
          this.dropdowns[index] = true;
        }

        this.$forceUpdate();
      }, 0);
    },
    closeDropdowns: function () {
      this.dropdowns = Array(this.getMainMenuItems.length + 1).fill(false);
    },
    updateScroll() {
      this.scrollPosition = window.scrollY;
    },
    onResize(e) {
      e.currentTarget.innerWidth >= 1200 ? this.closeMobileMenu() : null;
    },
    getMobile: function () {
      return {
        items: this.menuItems
      }
    },
  },
  mounted() {
    this.isPageLoaded = true;
    window.addEventListener('scroll', this.updateScroll);
    window.addEventListener('resize', this.onResize);
  },
  updated() {
    this.toggleBodyClass(this.showMobileMenu, 'overflow-hidden');
  },
  created() {
    window.updateCartItemCount = (value) => {
      this.cartItemsCount = value;
    };
  },
  computed: {
    isSticky() {
      let position = 20;
      if (this.showPhoneNumber === 1) {
        position = 54;
      }
      if (this.navigationTop === 1) {
        position = 1000000;
      }
      return this.scrollPosition < position;
    },
    sticky() {
      return {
        'colored': this.isSticky || !this.isSticky
      }
    },
    getMainMenuItems() {
      return this.menuItems.find(menu => menu.id == "mainMenu").items;
    }
  }
}
</script>

<style lang="scss">
p {
  font-size: 16px;
}

.close-btn {
  top: 10px;
  right: 10px;
  margin-left: 10px;
}

.transparent {
  transition: background 150ms linear;
  background: transparent;
  position: absolute;
  @media (min-width: 992px) {
    &.has-preheader {
      padding-top: 74px !important;
    }
  }
}

.navigation-color {
  color: #ffffff !important;
}

.colored {
  transition: background 150ms linear;
  background: rgba(#FFFFFF, 0.98);
  position: fixed;
  top: 0;
  padding: 15px 0 !important;
  @media (min-width: 992px) {
    &.has-preheader {
      padding-top: 50px !important;
    }
  }
  @media (max-width: 1199px) {
    padding: 16.5px 0 !important;
  }

  .navigation-color {
    color: #000000 !important;
  }
}

.blackfriday2019 .colored, .blackfriday2019online .colored, .blackfriday2019com .colored, .blackfriday2019xyz .colored {
  background: rgba(5, 5, 5, 0.98);
}

.flock-blue {
  background: #4f8ff9;
  position: fixed;
  padding: 20px 0 !important;
  @media (min-width: 992px) {
    &.has-preheader {
      padding-top: 74px !important;
    }
  }
}

.navigation-holder {
  min-height: 68px;
  max-width: 100vw;
  @media (min-width: 1200px) {
    min-height: 75px;
  }
}

.navigation-holder.colored {
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.08);
}

.colored-transparent {
  transition: background 150ms linear;
  position: fixed;
  top: 0;
  padding: 15px 0 !important;
  color: #2F1F68;
  @media (min-width: 992px) {
    &.has-preheader {
      padding-top: 50px !important;
    }
  }
  @media (max-width: 1199px) {
    padding: 16.5px 0 !important;
  }

  .navigation-color {
    color: #2F1F68 !important;
  }
}
</style>
