<template>
    <div class="position-relative">
        <div class="navigation-color fw-600 cursor-pointer hover-opacity-6 transition-3" :class="isLogin ? '' : 'mr-30'">
            <p v-if="hasDropdown()" v-on:click="toggle()" class="d-flex align-items-center fs-15 fs-17-xxl">
                {{ title }}
                <svg v-if="dropdown" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" :fill="sticky.colored || sticky['colored-transparent'] ? 'black' : 'white'" width="24px" height="24px"><path d="M0 0h24v24H0z" fill="none"/><path d="M7.41 15.41L12 10.83l4.59 4.58L18 14l-6-6-6 6z"/></svg>
                <svg v-else xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" :fill="sticky.colored || sticky['colored-transparent'] ? 'black' : 'white'" width="24px" height="24px"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z"/></svg>
            </p>
          <a class="fs-15 fs-17-xxl fw-600" :class="{'d-flex align-items-center border-h-dark border-radius-round pl-20 pr-20 p-2' : isLogin}"  v-else :href="href">
                <svg v-if="isLogin" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="mr-5" :fill="sticky.colored || sticky['colored-transparent'] ? 'black' : 'white'" width="24px" height="24px"><g fill="none"><path d="M0 0h24v24H0V0z"/><path d="M0 0h24v24H0V0z" opacity=".87"/></g><path d="M18 8h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zM9 6c0-1.66 1.34-3 3-3s3 1.34 3 3v2H9V6zm9 14H6V10h12v10zm-6-3c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2z"/></svg>
                {{ title }}
            </a>
        </div>
        <div v-if="dropdown && hasDropdown()" v-click-outside="close" class="position-absolute bg-white dropdown-holder transform-none border-radius-3 dropdown-holder">
            <div class="dropdown-item-wrapper pl-30 pr-30 pt-10 pb-30">
              <a :id="item.id" :href="item.href" v-for="item in items" :key="item.title" class="d-flex align-items-center scale-hover-1-05 transition-3 cursor-pointer mt-20">
                <img :src="item.logo" class="mr-20">
                <div>
                  <p class="fs-18 fw-700">{{ item.title }}</p>
                  <p class="fs-14">{{ item.text }}</p>
                </div>
              </a>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
    	props: {
    		title: String,
            href: String,
            items: {
    			type: Array,
                default: function() {
                	return []
				}
            },
            index: Number,
            dropdown: Boolean,
            sticky: Object,
            isLogin: Boolean
        },
        data: function() {
    		return {
    			opened: false
            }
        },
        methods: {
    		hasDropdown: function() {
    			return this.items.length > 0
             },
            toggle: function() {
                this.$emit('toggle', this.index, this.dropdown);
            },
            close: function() {
				this.$emit('close');
            }
        }
    }
</script>

<style lang="scss" scoped>
    .dropdown-holder{
        width: 400px;
        top:40px;
        left: 50%;
        box-shadow: 0 4px 9px 0 rgba(67, 65, 79, 0.1);
        transform: translate(-50%, 0);
    }
    .colored {
        .dropdown-holder {
            top: 53px;
        }
    }
    .dropdown-holder::before {
        content: "";
        position: absolute;
        top: -7px;
        left: 50%;
        width: 17px;
        height: 17px;
        transform: translate(-50%, 0) rotate(-315deg);
        border-radius: 3px;
        background-color: white;
    }
</style>