import {createApp} from 'vue'
import VueCookies from 'vue-cookies'

import HmenuNew from './components/menu/HmenuNew.vue'
import clickOutside from './directives/clickOutside.js'
import FooterNew from './components/footer/Hfooter.vue'
import RelatedProducts from './components/widgets/RelatedProducts'
import GdprBannerBottom from './components/gdpr/GdprBannerBottom'

const createAppFactory = (name, component, id) => {
    const app = createApp({})
    app.component(name, component)

    app.directive('clickOutside', clickOutside);
    app.use(VueCookies)

    app.config.compilerOptions.whitespace = 'preserve'
    app.mount( id )

    return app
}

createAppFactory('HmenuNew', HmenuNew, '#navigation-new')
createAppFactory('GdprBannerBottom', GdprBannerBottom, '#gdpr-consent')
createAppFactory('RelatedProducts', RelatedProducts, '#related-products-widget')
createAppFactory('FooterNew', FooterNew, '#footer-new')